<template style="background-color: #727a92">
  <div class="login-container" style="width: 55%;">
    <h2 class="title"><i class="icon-lock-open-1"></i>{{ $t('request.tips') }}</h2>
    <a-form @submit.prevent>
      <a-card :bordered="true" class="customCard" hoverable style="margin-bottom: 30px">
        <p class="cardTip" style="font-size: large;font-weight: 600">{{ $t('request.cardTip.base') }}</p>
        <a-divider/>
        <a-row style="margin-bottom: 10px">
          <a-col :span="12">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.symbol') }}: <small>(e.g.BTC)</small></p><input
                v-model="crtptoCurrency.symbol"
                :placeholder="$t('request.symbol')"
                autocomplete="off"
                type="text">
              <transition name="fade-in">
                <p v-if="errorTips.symbol" class="error-tips">{{ errorTips.symbol }}</p>
              </transition>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.cnFullname') }}:<small>(e.g.比特币)</small></p>
              <input v-model="crtptoCurrency.cnFullname"
                     :placeholder="$t('request.cnFullname')"
                     autocomplete="off"
                     type="text">
              <transition name="fade-in">
                <p v-if="errorTips.cnFullname" class="error-tips">{{ errorTips.cnFullname }}</p>
              </transition>
            </div>
          </a-col>
        </a-row>
        <a-row>

          <a-col :span="12">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.enFullname') }}:<small>(e.g.Bitcoin)</small></p>
              <input v-model="crtptoCurrency.enFullname"
                     :placeholder="$t('request.enFullname')"
                     autocomplete="off"
                     type="text">
              <transition name="fade-in">
                <p v-if="errorTips.enFullname" class="error-tips">{{ errorTips.enFullname }}</p>
              </transition>
            </div>
          </a-col>
          <a-col :span="12">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.totalSupply') }}:</p><input
                v-model="crtptoCurrency.totalSupply"
                :placeholder="$t('request.totalSupply')"
                autocomplete="off"
                type="text">
              <transition name="fade-in">
                <p v-if="errorTips.totalSupply" class="error-tips">{{ errorTips.totalSupply }}</p>
              </transition>
            </div>
          </a-col>
        </a-row>
        <a-row>


          <a-col :span="12">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.availableSupply') }}:</p><input
                v-model="crtptoCurrency.availableSupply"
                :placeholder="$t('request.availableSupply')"
                autocomplete="off"
                type="text">
              <transition name="fade-in">
                <p v-if="errorTips.availableSupply" class="error-tips">{{ errorTips.availableSupply }}</p>
              </transition>
            </div>
          </a-col>

        </a-row>
      </a-card>
      <a-card :bordered="true" class="customCard" hoverable style="margin-bottom: 30px">
        <p class="cardTip" style="font-size: large;font-weight: 500">{{ $t('request.cardTip.desc') }}</p>
        <a-divider/>
        <a-row>
          <a-col>
            <div class="form-item">
              <a-upload
                  :before-upload="beforeUpload"
                  :show-upload-list="false"
                  class="avatar-uploader"
                  list-type="picture-card"
                  name="avatar"
                  @change="handleChange"
              >
                <img v-if="imageUrl" :src="imageUrl" alt="avatar" style="width: 200px;height: 200px"/>
                <div v-else>
                  <a-icon :type="loading ? 'loading' : 'plus'"/>
                  <div class="ant-upload-text">
                    Logo
                  </div>
                </div>
              </a-upload>
            </div>

          </a-col>

          <a-row>
            <a-col>
              <div class="form-item">
                <p style="margin-top:8px;margin-bottom: 10px">{{ $t('request.issueDate') }}:</p>
                <a-date-picker placeholder="Select Time" show-time @change="onChange" @ok="onOk"/>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <div class="form-item">
                <p style="margin-top: 9px;margin-bottom: 10px">{{ $t('request.cnDescriptionTip') }}</p>
                <a-textarea v-model="crtptoCurrency.cnDescription"></a-textarea>
              </div>
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <div class="form-item">
                <p style="margin-bottom: 10px">{{ $t('request.enDescriptionTip') }}</p>
                <a-textarea v-model="crtptoCurrency.enDescription"></a-textarea>
              </div>
            </a-col>
          </a-row>

        </a-row>

      </a-card>
      <a-card :bordered="true" class="customCard" hoverable>
        <p class="cardTip" style="font-size: large;font-weight: 500">{{ $t('request.cardTip.link') }}</p>
        <a-divider/>
        <a-row>
          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.websiteUrl') }}:</p>
              <input v-model="crtptoCurrency.websiteUrl"
                     :placeholder="$t('request.websiteUrl')"
                     autocomplete="off"
                     type="text">
              <transition name="fade-in">
                <p v-if="errorTips.websiteUrl" class="error-tips">{{ errorTips.websiteUrl }}</p>
              </transition>
            </div>
          </a-col>

          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.whitePaperUrl') }}:</p> <input
                v-model="crtptoCurrency.whitePaperUrl"
                :placeholder="$t('request.whitePaperUrl')"
                autocomplete="off"
                type="text">
            </div>
          </a-col>

          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.githubUrl') }}:</p><input v-model="crtptoCurrency.githubUrl"
                                                                                      :placeholder="$t('request.githubUrl')"
                                                                                      autocomplete="off"
                                                                                      type="text">
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">

            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.explorerUrl') }}:</p> <input
                v-model="crtptoCurrency.explorerUrl"
                :placeholder="$t('request.explorerUrl')"
                autocomplete="off"
                type="text">
              <transition name="fade-in">
                <p v-if="errorTips.explorerUrl" class="error-tips">{{ errorTips.explorerUrl }}</p>
              </transition>
            </div>
          </a-col>
          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.facebookUrl') }}:</p><input
                v-model="crtptoCurrency.facebookUrl"
                :placeholder="$t('request.facebookUrl')"
                autocomplete="off"
                type="text">
            </div>
          </a-col>
          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.telegramUrl') }}:</p> <input
                v-model="crtptoCurrency.telegramUrl"
                :placeholder="$t('request.telegramUrl')"
                autocomplete="off"
                type="text">
            </div>
          </a-col>
        </a-row>
        <a-row>
          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.redditUrl') }}:</p> <input
                v-model="crtptoCurrency.redditUrl"
                :placeholder="$t('request.redditUrl')"
                autocomplete="off"
                type="text">
            </div>
          </a-col>

          <a-col :span="8">
            <div class="form-item">
              <p style="margin-bottom: 10px">{{ $t('request.twitterUrl') }}:</p><input
                v-model="crtptoCurrency.twitterUrl"
                :placeholder="$t('request.twitterUrl')"
                autocomplete="off"
                type="text">
            </div>
          </a-col>
        </a-row>


      </a-card>
      <center>
        <div class="form-item" style="width: 50%;margin-top: 20px">
          <Turnstile ref="turnstile" size="normal"/>
          <transition name="fade-in">
            <p v-if="errorTips.turnstile_response" class="error-tips">{{ errorTips.turnstile_response }}</p>
          </transition>
        </div>
      </center>
      <center>
        <div class="form-item" style="width: 50%;margin-top: 30px">
          <button class="button long" @click="submit">{{ $t('request.submit') }}</button>
        </div>
      </center>
      <div class="form-item">
        <a-tooltip
            arrowPointAtCenter
            overlayClassName="custom-tooltip"
            placement="bottom">
          <template slot="title">
            <i18n path="user.contactus" tag="p">
              <a class="contact" href="javascript:;"
                 @click="handleContact">{{ $t('user.customerService') }}</a>
            </i18n>
            <!-- <p>登录遇到困难可以<a href="javascript:;" class="contact" @click="handleContact">联系客服</a>，或者通过微信 BDmifengcha 解决。</p>-->
          </template>
        </a-tooltip>

      </div>

    </a-form>
  </div>
</template>
<script>
import Turnstile from "../components/Turnstile.vue";
import schema from 'async-validator';
import {applyCryptoCurrency} from '../services/apply'

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const numberReg = /^[1-9]\d{0,}\.{0,1}\d{0,}$|^[0]$/;
const BLACK_NAME = ['register', 'account-checkEmail', 'account-changePassword', 'login', 'email-active']
export default {
  name: 'applyCryptoCurrency',
  components: {
    Turnstile,
  },
  data() {
    const descriptor = {
      symbol: [{
        type: 'string',
        required: true,
        message: this.$t('request.enterSymbol'),
      }],
      websiteUrl: [{
        type: 'string',
        required: true,
        message: this.$t('request.enterWebsiteUrl'),
      }],
      explorerUrl: [{
        type: 'string',
        required: true,
        message: this.$t('request.enterExplorerUrl'),
      }],
      enFullname: [{
        type: 'string',
        required: true,
        message: this.$t('request.enterEnFullname'),
      }],
      totalSupply: [{
        type: 'string',
        required: true,
        message: this.$t('request.enterTotalSupply'),
      }, {
        pattern: numberReg,
        message: this.$t('request.enterLegalNumber')
      }],
      availableSupply: [{
        type: 'string',
        required: true,
        message: this.$t('request.enterAvailableSupply'),
      }, {
        pattern: numberReg,
        message: this.$t('request.enterLegalNumber')
      }],
      turnstile_response: [{
        type: 'string',
        required: true,
        message: this.$t('user.confirmAliCode'),
      }],
    }
    return {
      crtptoCurrency: {
        cnDescription: '',
        enDescription: '',
        locale: 'zh_CN',
        description: '',
        logoFileName: "",
        externalId: "",
        symbol: "",
        enFullname: "",
        cnFullname: "",
        logoUrl: "",
        totalSupply: "",
        availableSupply: "",
        maxSupply: "",
        websiteDomain: "",
        websiteUrl: "",
        introduction: "",
        explorerUrl: "",
        whitePaperUrl: "",
        githubUrl: "",
        twitterUrl: "",
        facebookUrl: "",
        telegramUrl: "",
        redditUrl: "",
        contractAddress: "",
        issueDate: "",
        turnstile_response: '',
      },
      uploadFile: {
        picFile: "",
      },
      illegalImg: false,
      loading: false,
      imageUrl: '',
      errorTips: {
        symbol: '',
        // password: '',
        externalId: '',
        enFullname: '',
        cnFullname: '',
        totalSupply: '',
        availableSupply: '',
        webSite: '',
        maxSupply: '',
      },
      validator: new schema(descriptor),
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (from.name && !BLACK_NAME.includes(from.name)) {
        vm.$router.push({name: 'login', query: {redirect_url: from.fullPath}});
      }
    })
  },
  mounted() {

  },
  methods: {
    beforeUpload(file) {
      const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        this.illegalImg = false
        this.$message.error(this.$t('request.illegalImgType'));
        return false;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.illegalImg = false
        this.$message.error(this.$t('request.illegalImgSize'));
        return false;
      }
      this.illegalImg = true
      return false;
    },
    handleChange(info) {
      if (this.illegalImg) {
        getBase64(info.file, imageUrl => {
          this.crtptoCurrency.logoFileName = info.file.name
          this.imageUrl = imageUrl;
          this.loading = false;
          this.crtptoCurrency.logoUrl = imageUrl;
        });
      }


    },
    onChange(value, dateString) {
      this.crtptoCurrency.issueDate = dateString;
      console.log("当前的issdate" + this.crtptoCurrency.issueDate)
    },
    onOk(value) {
      console.log('onOk: ', value);
    },
    async submit() {

      this.crtptoCurrency.turnstile_response = await this.$refs.turnstile.getResponse();
      this.$refs.turnstile.reset();
      if (this.crtptoCurrency.logoFileName == null || this.crtptoCurrency.logoFileName == "") {
        this.$error({
          title: this.$t('request.enterLogo')
        })
        this.clearObj(_that.crtptoCurrency)
        return
      }
      var _that = this;
      this.validator.validate(this.crtptoCurrency).then(async () => {
        await this.$store.dispatch('initAuth', true);
        applyCryptoCurrency(this.crtptoCurrency)
            .then(async res => {
              if (res.code === 50002) {
                this.$error({
                  title: this.$t('error["error.request.already.exist"]'),

                })
                _that.clearObj(_that.crtptoCurrency)


              } else if (res.code === 0) {
                this.$message.success(this.$t('request.submitSuccess'));
                this.$router.push({name: 'index'})
              }
            })
            .catch(err => {

              if (err.code === 403) {
                this.$error({
                  title: this.$t('request.withoutLogin')
                })
              } else {
                this.$error({
                  title: this.$t(`error['${err.response.data.errorKey}']`)
                });
              }
              _that.clearObj(_that.crtptoCurrency)

            })

      }).catch(({errors, fields}) => {
        let errorTips = {}
        if (!errors) return;
        errors.forEach(error => {
          if (!errorTips[error.field]) {
            errorTips[error.field] = error.message;
          }
        })
        this.errorTips = errorTips;
      })
    },
    clearObj(obj) {
      for (let key in obj) {
        obj[key] = ''
      }
      obj[scene] = 'nc_login'
    },
    handleContact() {
      document.querySelector('.chat-btn').click();
    },
  }
}
</script>
<style lang="less" scoped>
@import '../assets/css/theme.less';

.file {
  position: relative;
  display: inline-block;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 4px 12px;
  overflow: hidden;
  color: #727a92;
  text-decoration: none;
  text-indent: 0;
  line-height: 20px;
}

.fileInput input :hover {
  cursor: pointer;
}

.file input {
  position: absolute;
  font-size: 100px;
  right: 0;
  top: 0;
  opacity: 0;
}

#inputFile :hover {
  cursor: pointer;
}

.file:hover {
  cursor: pointer;
}

.login-container {
  width: 500px;
  margin: 60px auto;
  // border: 1px solid #f1f1f1;
  border-radius: 3px;

  h2 {
    font-size: 20px;
    padding: 20px 40px;
    margin-bottom: 20px;
    text-align: center;

    i {
      margin-right: 10px;
    }
  }
}

.form-item {
  padding: 0 40px;
  margin-bottom: 20px;
  position: relative;

  input {
    width: 100%;
    padding: 0 20px;
    background-color: #fff;
    height: 40px;
    border: 1px solid #dcdfe6;
    border-radius: 4px;

    &:-webkit-autofill {
      box-shadow: 0 0 0px 1000px white inset !important;
    }

    &:focus {
      border-color: @primary-color;
    }
  }

  .error-tips {
    position: absolute;
    left: 40px;
    font-size: 12px;
    padding-top: 4px;
    color: @red-color;
  }
}

.handle-btn {
  margin-bottom: 10px;

  &:hover, &.primary {
    color: @blue-color;
  }
}

.long {
  width: 100%;
  margin: 0;
}

.button {
  margin: 0;
}

.contact {
  color: @primary-color;
}
</style>
